<template>
  <div class="pin-page h-100 text-center">
    <b-container 
      fluid 
      class="h-100"
    >
      <b-row class="h-100 align-content-center">
        <b-col cols="12">
          <b-navbar 
            variant="faded" 
            type="light"
          >
            <b-navbar-brand href="#" />
          </b-navbar>

          <b-modal
            id="modal-sync-item-2"
            hide-footer
            hide-header
            modal-class="success-popup"
            ok-only
            no-stacking
            centered
            no-close-on-backdrop
          >
            <div class="text-center modal-complete">
              <div class="success-popup-icon success-popup-icon_success">
                <svg class="icon icon-icon-popup-sync">
                  <use xlink:href="#icon-icon-popup-sync" />
                </svg>
              </div>

              <h2>
                {{ $t("syncprogressdialog.syncing") }}
              </h2>

              <b-progress
                :max="max"
                :striped="false"
                :animated="true"
                height="10px"
                show-progress
              >
                <b-progress-bar
                  :value="syncProgress"
                  variant="success"
                >
                  <h5 v-if="syncProgress > 0">
                    <svg class="icon icon-icon-popup-sync">
                      <use xlink:href="#icon-icon-popup-sync" />
                    </svg>
                  </h5>
                </b-progress-bar>
              </b-progress>

              <div class="d-flex justify-content-center align-items-center">
                <b-button 
                  style="display: none" 
                  variant="outline-success w-100" 
                  @click="cancel()"
                >
                  {{ $t("syncprogressdialog.cancel") }}
                </b-button>
              </div>
            </div>
          </b-modal>
        </b-col>
      </b-row>

      <scale-loader
        v-if="spinner.loading"
        :color="spinner.color"
        :size="spinner.size"
      />
    </b-container>
  </div>
</template>

<script>
import router from "../router";
import { ORDER_STATUS } from "../helpers/order/status";
import indexDbService from "@/helpers/dbHelper";
import * as Sentry from "@sentry/vue";

export default {
  name: "AppPin",
  components: {
    ScaleLoader: () => import("vue-spinner/src/ScaleLoader.vue")
  },
  data () {
    return {
      timer: 0,
      max: 100,
      spinner: {
        loading: true,
        size: "50px",
        color: "#007bff"
      }
    };
  },
  computed: {
    syncProgress () {
      return this.$store.getters.syncProgress;
    },
    terminal () {
      return this.$store.getters.terminal;
    },
    isKioskMode () {
      return this.$store.getters['config/isKioskMode'];
    }
  },
  mounted () {
    this.spinner.loading = false;
    this.$bvModal.show("modal-sync-item-2");
  },
  created: async function () {
    // upload any offline data to the server
    await this.$store.dispatch("orders/uploadOfflineOrders")
    await this.$store.dispatch("giftVoucher/updateGiftCardCredits")
    // clear all the data from index db
    await indexDbService.clearAllData()
    this.$bvModal.show("modal-sync-item-2");
    const syncQueue = [
      "config/fetchTerminal",
      "config/fetchCategories",
      "config/fetchProducts",
      "config/fetchSections",
      "config/fetchTables",
      "config/fetchDiscounts",
      "config/fetchEmployees",
      "customers/fetch",
      // "orders/uploadOrders",
    ];
    if(!this.isKioskMode){
      syncQueue.push("fetchCurrentShift")
    }
    const percentIncrement = syncQueue.length ? 100 / syncQueue.length : 0;
    this.$store.commit("setSyncProgress", 5);
    for (const syncItem of syncQueue) {
      try {
        await this.$store.dispatch(syncItem);
        this.$store.commit("setSyncProgress", this.$store.getters.syncProgress + percentIncrement);
      } catch (e) {
        Sentry.captureException(e);
        if (e.message.includes('Got 401 at')){
          await this.$store.dispatch("config/resetAuthConfig");
          await router.push({ name: "Reset" });
        }
      }
    }
    Object.values(ORDER_STATUS).forEach(async (orderStatus) => {
      const payload = {
        orderStatus,
        firstPage: 1,
        initial: true
      }
      await this.$store.dispatch('orders/fetchOrderListByStatus', payload)
    })
    this.$bvModal.hide("modal-sync-item-2");
    await router.push({ name: "Pin" });
  }
};
</script>

<style lang="scss" scoped>
.pin-page {
  background: linear-gradient(180deg, #1976D2 0%, #2196F3 100%);
  color: #fff;
  min-height: 100vh;

  h6 {
    font-size: 15px;
    line-height: 18px;
  }

  .pin-block {
    padding-top: 15px;
  }
}

.navbar {
  padding: 30px 0 30px;
}

.navbar-brand {
  padding: 0;
  margin: 0 auto;
  height: 60px;

  img {
    max-height: 100%;
    height: 100%;
  }
}
</style>
